import { ReactComponent as UsersIcon } from "@assets/images/users.svg";
import { ReactComponent as CompaniesIcon } from "@assets/images/companies.svg";
import { ReactComponent as SubAdminIcon } from "@assets/images/sub-admin.svg";
import { ReactComponent as SettingsIcon } from "@assets/images/settings.svg";
import { ReactComponent as IndustryIcon } from "@assets/images/industry.svg";
import { ReactComponent as JobFunctionIcon } from "@assets/images/job-function.svg";
import { ReactComponent as ListIcon } from "@assets/images/list.svg";
import { ReactComponent as DashboardIcon } from "@assets/images/dashboard.svg";
import { ReactComponent as ClientUsersIcon } from "@assets/images/client-user-icon.svg";
import { ReactComponent as ClientSubAdminsIcon } from "@assets/images/client-sub-admins-icon.svg";
import { ReactComponent as GradeSetupIcon } from "@assets/images/client-grade-setup-icon.svg";
import { ReactComponent as SalaryRangeIcon } from "@assets/images/client-salary-range-icon.svg";
import { ReactComponent as CashAllowancesIcon } from "@assets/images/client-cash-allowances-icon.svg";
import { ReactComponent as STIPlanIcon } from "@assets/images/client-sti-plan-icon.svg";
import { ReactComponent as LTIPlanIcon } from "@assets/images/client-lti-plan-icon.svg";
import { ReactComponent as InternalPayrollIcon } from "@assets/images/client-internal-payroll-icon.svg";
import { ReactComponent as MarketDataIcon } from "@assets/images/client-market-data-icon.svg";
import { ReactComponent as CompensationSystemIcon } from "@assets/images/client-compensation-system-icon.svg";
import { ReactComponent as ClientDashboardIcon } from "@assets/images/client-dashboard-icon.svg";
import { ReactComponent as ClientCreateOfferIcon } from "@assets/images/client-create-offer-icon.svg";
import { ReactComponent as ClientOfferModellerIcon } from "@assets/images/client-offer-modeller-icon.svg";
import { paths, permissions } from "@router";
import { IJobGrade } from "@/types";
import { checkPermission } from "@/utils";

const {
  admin: {
    users: { companies, sub_admins },
    dashboard,
    settings: { sectors, job_function, grade_map_table },
  },
  client,
} = paths;

export interface IConfig {
  title: string;
  icon: any;
  path: string | Array<string>;
  routingPath?: string;
  sub?: Array<IConfig>;
  permission?: string | Array<string>;
  client?: boolean;
  disabled?: boolean;
  fallbackMessage?: string;
}

export const admin_config: Array<IConfig> = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    path: dashboard.dashboard,
  },
  {
    title: "Users",
    icon: UsersIcon,
    path: [
      companies.listing,
      companies.create,
      sub_admins.create,
      sub_admins.listing,
    ],
    sub: [
      {
        title: "Companies",
        icon: CompaniesIcon,
        path: "/companies",
      },
      {
        title: "Sub-Admins",
        icon: SubAdminIcon,
        path: sub_admins.listing,
        permission: permissions.VIEW_TOM_USER,
      },
    ],
  },
  {
    title: "Settings",
    icon: SettingsIcon,
    path: [
      sectors.listing,
      sectors.industry,
      job_function.listing,
      job_function.sub_job_function,
      grade_map_table.listing,
      grade_map_table.create_grade_company,
    ],
    sub: [
      {
        title: "Sectors, Industry & Sub-Industry",
        icon: IndustryIcon,
        path: sectors.listing,
        permission: permissions.VIEW_SECTOR,
      },
      {
        title: "Job Function & Sub-Function",
        icon: JobFunctionIcon,
        path: job_function.listing,
        permission: permissions.VIEW_JOB_SUB_FUNCTION,
      },
      {
        title: "Grade map table",
        icon: ListIcon,
        path: grade_map_table.listing,
      },
    ],
  },
];

export const client_config = (
  jobGrades: IJobGrade[],
  company_id: string
): Array<IConfig> => [
  {
    title: "Dashboard",
    icon: ClientDashboardIcon,
    path: client.dashboard,
    client: true,
  },
  ...(!checkPermission([
    permissions.VIEW_COMPANY_USER,
    permissions.VIEW_BUSINESS_UNIT,
    permissions.VIEW_REGION,
  ])
    ? []
    : [
        {
          title: "Users",
          icon: ClientUsersIcon,
          client: true,
          path: [
            client.companies.profile.details,
            client.sub_admins.create,
            client.sub_admins.listing,
          ],
          sub: [
            {
              title: "My Company",
              icon: CompaniesIcon,
              path: client.companies.profile.details.replace(
                ":company_id",
                company_id
              ),
              permission: [
                permissions.VIEW_COMPANY_USER,
                permissions.VIEW_BUSINESS_UNIT,
                permissions.VIEW_REGION,
              ],
            },
            {
              title: "Sub-Admins",
              icon: ClientSubAdminsIcon,
              path: client.sub_admins.listing,
              permission: permissions.VIEW_COMPANY_USER,
            },
          ],
        },
      ]),
  {
    title: "Compensation System",
    icon: CompensationSystemIcon,
    client: true,
    path: [
      client.grade_setup,
      client.salary_range,
      client.cash_allowances,
      client.short_term_ip.plans,
      client.short_term_ip.incentives,
      client.long_term_ip.plans,
      client.long_term_ip.incentives,
      client.internal_payroll_data,
      client.market_data,
    ],
    sub: [
      {
        title: "Grade Setup",
        icon: GradeSetupIcon,
        path: client.grade_setup,
        permission: permissions.VIEW_JOB_GRADE,
      },
      {
        title: "Salary Range",
        icon: SalaryRangeIcon,
        path: client.salary_range,
        permission: permissions.VIEW_SALARY_RANGE,
      },
      {
        title: "Cash Allowances",
        icon: CashAllowancesIcon,
        path: client.cash_allowances,
        permission: permissions.VIEW_CASH_ALLOWANCE,
      },
      {
        title: `Short Term Incentives`,
        icon: STIPlanIcon,
        path: client.short_term_ip.plans,
        permission: permissions.VIEW_COMPANY_STI,
      },
      {
        title: "Long Term Incentives",
        icon: LTIPlanIcon,
        path: client.long_term_ip.plans,
        permission: permissions.VIEW_COMPANY_LTI,
      },
      {
        title: "Internal Payroll",
        icon: InternalPayrollIcon,
        path: client.internal_payroll_data,
        permission: permissions.VIEW_INTERNAL_PAYROLL,
      },
      {
        title: "Market Data",
        icon: MarketDataIcon,
        path: client.market_data,
        permission: permissions.VIEW_MARKET_DATA,
      },
    ],
  },
  {
    disabled: jobGrades?.length ? false : true,
    fallbackMessage: "Please create any grade to access offers.",
    title: "Offer Modeller",
    icon: ClientOfferModellerIcon,
    client: true,
    path: [
      client.offers.active,
      client.offers.past,
      client.offers.drafts,
      client.offers.create,
    ],
    sub: [
      {
        title: "Active",
        icon: ClientCreateOfferIcon,
        path: client.offers.active,
        permission: permissions.VIEW_OFFER,
      },
      {
        title: "Past",
        icon: ClientCreateOfferIcon,
        path: client.offers.past,
        permission: permissions.VIEW_OFFER,
      },
      {
        title: "Drafts",
        icon: ClientCreateOfferIcon,
        path: client.offers.drafts,
        permission: permissions.VIEW_OFFER,
      },
    ],
  },
];
