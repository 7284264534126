import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import "@styles/index.less";
import { admin_routeConfig, client_routeConfig, paths, Routes } from "@router";
import { fetchCountries, saveTokens } from "@services";
import { useVerifyMutation } from "@services";
import Layout from "@components/Layout";
import { useTypedSelector } from "@hooks";
import { adminVars, clientVars, ssoUrl } from "./constants";
import { isAdmin } from "./utils";
import { matchPath } from "react-router-dom";
import {
  getSelectedCompanyFromStorage,
  getTokenFromSharedStorage,
} from "./services/cookies";

declare global {
  interface Window {
    less: any;
  }
}

const App = () => {
  const dispatch = useDispatch();
  const { countries } = useTypedSelector(state => state.countries);
  const { user } = useTypedSelector(state => state.auth);
  const match = matchPath(window.location.pathname, {
    path: [...Object.values(paths.client.auth)],
  });
  const [verifyUser, { isLoading, status }] = useVerifyMutation();

  useEffect(() => {
    if (!countries?.length && user?.id) {
      dispatch(fetchCountries());
    }
  }, [dispatch, countries, user]);

  useEffect(() => {
    if (window?.less?.modifyVars) {
      if (match || isAdmin(user?.role!)) {
        window?.less?.modifyVars(adminVars);
      } else {
        window?.less?.modifyVars(clientVars);
      }
    }
    loginUserFromCookies();
  }, []);

  const loginUserFromCookies = async () => {
    const ssoTokens = getTokenFromSharedStorage();
    if (ssoTokens) {
      saveTokens(ssoTokens);
      const companyId = getSelectedCompanyFromStorage();
      const { data: userData } = await verifyUser({
        selected_application: process.env.REACT_APP_APPLICATION || "TOM",
        companyId,
      }).unwrap();
      saveTokens(userData.token);
    } else {
      window.open(ssoUrl, "_self");
    }
  };

  if (status === QueryStatus.rejected) {
    window.open(ssoUrl, "_self"); // if Auth Verification failed. open SSOlogin page
  }

  return (
    <Layout>
      {isLoading ||
      status === QueryStatus.pending ||
      status === QueryStatus.uninitialized ? null : (
        <>
          <Routes
            routes={
              isAdmin(user?.role!) ? admin_routeConfig : client_routeConfig
            }
          />
        </>
      )}
    </Layout>
  );
};

export default App;
