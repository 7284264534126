/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, message, Row, Select } from "antd";
import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

import "./addGradeCompany.less";
import Table from "@components/Table";
import { ReactComponent as PlusIcon } from "@assets/images/plus.svg";
import {
  gradeSetupApi,
  useCreateGradeCompanyMutation,
  useFetchAllGradeCompaniesQuery,
  useFetchGradeClientCompaniesQuery,
  useFetchGradeCompaniesQuery,
  useFetchTARanksQuery,
  useUpdateGradeCompanyMutation,
} from "@services";
import { IGradeCompanyRank, IGradeCompany, ITARank, IJobGrade } from "@types";
import { getRows, showSuccessPopup } from "@utils";
import { paths } from "@router";
import { useEffect } from "react";

const { Option } = Select;

interface IGradeClientCompany {
  id: number;
  name: string;
}

const default_cols = [
  {
    title: "ta rank",
    dataIndex: "rank",
    key: "taRank",
    width: "10%",
  },
];

const AddGradeCompany = () => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation<{
    grade_company: IGradeCompany;
  }>();
  const [jobGrades, setJobGrades] = useState<IJobGrade[]>([]);

  const history = useHistory();
  const isEdit =
    pathname.includes("edit") && state?.grade_company?.grade_company_ranks;
  // const { allGradeCompanies, taRanks } = useTypedSelector(state => state.grade);
  const { data: taRanksData } = useFetchTARanksQuery(null);
  const { data: taRanks } = taRanksData || {};
  const { data: companiesData } = useFetchAllGradeCompaniesQuery(null);
  const { data: allGradeCompanies } = companiesData || {};
  const [companies, setCompanies] = useState<string[]>([]);
  const {
    data: gradeClientCompaniesData,
    isLoading: isLoadingClientCompanies,
  } = useFetchGradeClientCompaniesQuery(null);
  const { data: gradeCompaniesData, isLoading: isLoadingGradeCompanies } =
    useFetchGradeCompaniesQuery(null);
  const { data: gradeClientCompanies } = gradeClientCompaniesData || {};
  const { data: gradeCompanies } = gradeCompaniesData || {};

  const [createGradeCompany, { isLoading: isCreating }] =
    useCreateGradeCompanyMutation();
  const [updateGradeCompany, { isLoading: isUpdating }] =
    useUpdateGradeCompanyMutation();
  const [gradeCompany, setGradeCompany] = useState<null | IGradeClientCompany>(
    isEdit
      ? {
          id: +state?.grade_company?.company!,
          name: state?.grade_company?.name,
        }
      : null
  );
  // const [companyName] = useState<string>(
  //   isEdit ? state?.grade_company?.name : ""
  // );

  const [newCompany, setNewCompany] = useState(() =>
    isEdit
      ? state?.grade_company?.grade_company_ranks?.map(
          ({ rank, ta_rank_id }: IGradeCompanyRank) => ({
            rank: !rank?.length ? null : rank,
            ta_rank_id,
          })
        )
      : taRanks?.map((rank: ITARank) => ({ ta_rank_id: rank.id, rank: null }))
  );

  const additional_cols: any = useMemo(() => {
    return (
      allGradeCompanies?.map((company: IGradeCompany) => ({
        title: company.name,
        dataIndex: company.name.replace(" ", ""),
        key: company.id,
        width: "10%",
      })) || []
    );
  }, [allGradeCompanies]);
  const columns = [...default_cols, ...additional_cols];

  const rows = getRows(taRanks, allGradeCompanies) || [];
  /**
   * The purpose below is to show/hide and sort the columns
   * it relies on companies dropdown
   */
  const cols = useMemo(() => {
    const cols = [
      ...columns
        .filter(
          column =>
            companies.includes(column.title) || column.dataIndex === "rank"
        )
        .slice()
        .sort(function (a, b) {
          if (a.dataIndex === "name") return 1;
          return (
            companies.indexOf(a.dataIndex) - companies.indexOf(b.dataIndex)
          );
        }),
      {
        title: gradeCompany || "Name here...",
        dataIndex: "name",
        key: "name",
        width: 50,

        render: (_: any, item: any) => {
          return (
            <Select
              value={
                newCompany && newCompany[item?.rank - 1]
                  ? newCompany[item?.rank - 1]?.rank
                  : undefined
              }
              onChange={grade => {
                setNewCompany((prev: any) => {
                  const arr = [...prev];
                  arr[item?.rank - 1].rank = grade;
                  return arr;
                });
              }}
              className="table__input"
              size="large"
              showArrow={true}
              placeholder="Select grade from here..."
            >
              {jobGrades &&
                jobGrades?.length &&
                jobGrades?.map(({ grade, id }) => (
                  <Option key={id} value={grade}>
                    {grade}
                  </Option>
                ))}
            </Select>
          );
        },
      },
    ];
    return cols;
  }, [companies, columns]);

  useEffect(() => {
    if (isEdit && gradeCompany) {
      fetchJobGrades(gradeCompany?.id);
    }
  }, [isEdit]);

  const fetchJobGrades = async (company_id: number) => {
    const res: any = await dispatch(
      gradeSetupApi.endpoints.fetchAllCompanyJobGrades.initiate({
        company_id: company_id,
      })
    );
    if (res && "data" in res) {
      setJobGrades(res?.data?.data!);
    }
  };

  const handleDropdown = (value: string[]) => {
    setCompanies(value);
  };

  const addGrade = () => {};

  const onCancel = () => {
    setInitialState();
    history.goBack();
  };

  const handleSubmit = async () => {
    try {
      if (isEdit) {
        await editGradeCompany();
      } else {
        await addGradeCompany();
      }
      showSuccessPopup({
        title: isEdit ? "Grade Company Updated" : "New Grade Company Added",
        desc: `You have successfully ${
          isEdit ? "updated the" : "added new"
        } grade company`,
        onClick: () =>
          history.push(paths.admin.settings.grade_map_table.listing),
      });
    } catch (error) {
      message.success(error?.message);
      console.log(error);
    }
  };

  const addGradeCompany = async () => {
    const company_id = gradeClientCompanies.find(
      (company: IGradeClientCompany) => company.name === gradeCompany?.name
    ).id;
    await createGradeCompany({
      company_id,
      grades: newCompany,
    });
    setInitialState();
  };

  const editGradeCompany = async () =>
    await updateGradeCompany({
      id: state?.grade_company?.id,
      grades: newCompany,
    });

  const setInitialState = () => {
    setNewCompany(
      taRanks?.map((rank: ITARank) => ({ ta_rank_id: rank.id, rank: null }))
    );
    setGradeCompany(null);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <div className="main-heading mb-16">
            {isEdit ? "Update the Company" : "Add a Company"}
          </div>
        </Col>
      </Row>
      <div className="addGradeCompany">
        <Row className="addGradeCompany__header">
          <div className="sub-heading">Basic information</div>
        </Row>
        <Row justify="space-between" className="addGradeCompany__fields">
          <Col span={9}>
            <label>Company name</label>
            <Select
              disabled={!!isEdit}
              loading={isLoadingClientCompanies}
              value={
                gradeCompany?.name?.length ? gradeCompany?.name : undefined
              }
              size="large"
              showArrow
              placeholder="Select company name from here..."
              showSearch={false}
              onChange={(company: string) => {
                const parsedCompany = JSON.parse(company);
                setGradeCompany(parsedCompany);
                fetchJobGrades(parsedCompany?.id);
              }}
            >
              {gradeClientCompanies?.map(
                ({ name, id }: IGradeClientCompany) => (
                  <Option key={id} value={JSON.stringify({ name, id })}>
                    {name}
                  </Option>
                )
              )}
            </Select>
          </Col>
          {!isEdit ? (
            <>
              <Col span={9}>
                <label>
                  Choose company{" "}
                  <span className="addGradeCompany__fields--optional">
                    (optional)
                  </span>
                </label>
                <Select
                  loading={isLoadingGradeCompanies}
                  value={companies}
                  size="large"
                  showArrow
                  mode="multiple"
                  placeholder="Select industry from here..."
                  showSearch={false}
                  onChange={handleDropdown}
                >
                  {gradeCompanies?.map(
                    ({ name, id }: { name: string; id: number }) => (
                      <Option key={id} value={name}>
                        {name}
                      </Option>
                    )
                  )}
                </Select>
              </Col>
              <Col span={3} />
            </>
          ) : null}
        </Row>
        <Row>
          <div className="sub-heading addGradeCompany__title">
            {`${isEdit ? "Update" : "Create"} Grade Table`}
          </div>

          <Table pagination={false} columns={[...cols]} data={rows} />
          <div className="addGradeCompany__lastRow">
            <div
              onClick={addGrade}
              className="addGradeCompany__lastRow__button"
            >
              <PlusIcon fill="#2f49d1" />
              Add Grade
            </div>
          </div>
        </Row>
      </div>
      <div className="addGradeCompany__buttons">
        <Button
          disabled={!isEdit && !gradeCompany?.name?.length}
          onClick={handleSubmit}
          type="primary"
        >
          {isCreating || isUpdating ? (
            <LoadingOutlined className="spinner" />
          ) : (
            `${isEdit ? "Update" : "Add"} Grade Company`
          )}
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </>
  );
};

export default AddGradeCompany;
